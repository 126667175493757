import React from "react"

export default function PrivacyPolicy() {
  return (
    <>
      <h2 className="h1 text-center my-4">HR Privacy Policy</h2>
      <div className="text-block">
        <p>
          We take the protection of your applicant data very seriously and
          hereby want to inform you on how we process your applicant data. We,
          the finderly GmbH, Donau-City-Straße 1, 1220 Vienna, Austria, act as
          data controller with regard to your applicant data according to the
          rules of the General Data Protection Regulation (GDPR).
        </p>
        <p>
          We will use your personal data only for the purpose of recruiting
          employees and for the maintenance of our ongoing applicant database.
          For this purpose, we collaborate with a third-party provider. We have
          entered into a data processor agreement within the meaning of GDPR
          with this third-party provider. The third-party provider will only
          store the data on our behalf.
        </p>
        <p>
          Without your explicit consent, your personal data will be deleted
          within 6 months after the application process ended. After this period
          your data will be deleted automatically. The storage of your applicant
          data is based on our legitimate interests as laid down in article 6
          par 1 lit f) GDPR.
        </p>
        <p>
          With your voluntary declaration of consent, you may agree that your
          personal data will be stored for a period of two years so that we can
          keep contact in case of a suitable job offer. After this period your
          data will be deleted automatically. In case you consent to this
          extended processing of your data, you have at any time the right to
          withdraw your consent via e-mail to{" "}
          <a href="mailto:jobs@shpock.com">jobs@shpock.com</a>.
        </p>
        <p>
          You have at any time the right to request information if and what of
          your personal data we store via email to{" "}
          <a href="mailto:jobs@shpock.com">jobs@shpock.com</a>. Further, you can
          request the rectification, completion, restriction or deletion of your
          personal data at any time. Moreover, you have the right to contact the
          competent data protection authority (
          <a href="https://www.dsb.gv.at/">www.dsb.gv.at</a>) if you want to
          file a complaint.
        </p>
        <p>
          Our data protection officer can be reached under{" "}
          <a href="mailto:datenschutzbeauftragter@shpock.com">
            datenschutzbeauftragter@shpock.com
          </a>
          . Our general privacy policy can be found{" "}
          <a href="https://www.shpock.com/en-gb/privacy-policy">here</a>.
        </p>
      </div>
    </>
  )
}
